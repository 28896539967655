import axios from 'axios';
import { memorise } from 'lru-memorise';
import { getGlobalisedValue as getGlobalisedValue$1 } from 'global-const';

const isNode = () => typeof window === 'undefined';
const isBrowser = () => !isNode();

var Detail;
(function (Detail) {
    Detail["RUNTIME_ID"] = "rid";
    Detail["PASSPORT_CLIENT_ID"] = "passportClientId";
    Detail["ENVIRONMENT"] = "env";
    Detail["PUBLISHABLE_API_KEY"] = "pak";
    Detail["IDENTITY"] = "uid";
    Detail["DOMAIN"] = "domain";
    Detail["SDK_VERSION"] = "sdkVersion";
})(Detail || (Detail = {}));

const IMTBL_API = 'https://api.immutable.com';
async function post(path, data) {
    const client = axios.create({
        baseURL: IMTBL_API,
    });
    const payload = JSON.stringify(data);
    const body = {
        payload: Buffer.from(payload).toString('base64'),
    };
    const response = await client.post(path, body);
    return response.data;
}

/**
 * Abstraction on localstorage
 */
const localStoragePrefix = '__IMX-';
const hasLocalstorage = () => isBrowser() && window.localStorage;
const parseItem = (payload) => {
    // Try to parse, if can't be parsed assume string
    // and return string
    if (payload === null)
        return undefined;
    try {
        return JSON.parse(payload);
    }
    catch (error) {
        // Assumes it's a string.
        return payload;
    }
};
const serialiseItem = (payload) => {
    if (typeof payload === 'string') {
        return payload;
    }
    return JSON.stringify(payload);
};
/**
 * GenKey will take into account the namespace
 * as well as if being run in the Link, it will tap into the link
 * @param {string} key
 * @returns key
 */
const genKey = (key) => `${localStoragePrefix}${key}`;
function getItem(key) {
    if (hasLocalstorage()) {
        return parseItem(window.localStorage.getItem(genKey(key)));
    }
    return undefined;
}
const setItem = (key, payload) => {
    if (hasLocalstorage()) {
        window.localStorage.setItem(genKey(key), serialiseItem(payload));
        return true;
    }
    return false;
};

var Store;
(function (Store) {
    Store["EVENTS"] = "events";
    Store["RUNTIME"] = "runtime";
})(Store || (Store = {}));
// In memory storage for events and other data
let EVENT_STORE;
let RUNTIME_DETAILS;
// Initialise store and runtime
const initialise$1 = () => {
    EVENT_STORE = getItem(Store.EVENTS) || [];
    RUNTIME_DETAILS = getItem(Store.RUNTIME) || {};
};
initialise$1();
// Runtime Details
const storeDetail = (key, value) => {
    RUNTIME_DETAILS = {
        ...RUNTIME_DETAILS,
        [key]: value,
    };
    setItem(Store.RUNTIME, RUNTIME_DETAILS);
};
const getDetail$1 = (key) => {
    // Handle the scenario where detail is a falsy value
    if (RUNTIME_DETAILS[key] === undefined) {
        return undefined;
    }
    return RUNTIME_DETAILS[key];
};
const getAllDetails = () => RUNTIME_DETAILS;
// Events
const getEvents = () => EVENT_STORE;
const addEvent = (event) => {
    EVENT_STORE.push(event);
    setItem(Store.EVENTS, EVENT_STORE);
};
const removeSentEvents = (numberOfEvents) => {
    EVENT_STORE = EVENT_STORE.slice(numberOfEvents);
    setItem(Store.EVENTS, EVENT_STORE);
};
const flattenProperties = (properties) => {
    const propertyMap = [];
    Object.entries(properties).forEach(([key, value]) => {
        if (typeof key === 'string'
            || typeof value === 'string'
            || typeof value === 'number'
            || typeof value === 'boolean') {
            propertyMap.push([key, value.toString()]);
        }
    });
    return propertyMap;
};

// WARNING: DO NOT CHANGE THE STRING BELOW. IT GETS REPLACED AT BUILD TIME.
const SDK_VERSION = '__SDK_VERSION__';
const getFrameParentDomain = () => {
    if (isNode()) {
        return '';
    }
    // If available for supported browsers (all except Firefox)
    if (window.location.ancestorOrigins
        && window.location.ancestorOrigins.length > 0) {
        return new URL(window.location.ancestorOrigins[0]).hostname;
    }
    // Fallback to using the referrer
    return document.referrer ? new URL(window.document.referrer).hostname : '';
};
const runtimeHost = () => {
    if (isNode()) {
        return '';
    }
    let domain;
    try {
        if (window.self !== window.top) {
            domain = getFrameParentDomain();
        }
    }
    catch (error) {
        // Do nothing
    }
    // Fallback to current domain if can't detect parent domain
    if (!domain) {
        domain = window.location.hostname;
    }
    return domain;
};
const getRuntimeDetails = () => {
    storeDetail(Detail.SDK_VERSION, SDK_VERSION);
    if (isNode()) {
        return { browser: 'nodejs', sdkVersion: SDK_VERSION };
    }
    const domain = runtimeHost();
    if (domain) {
        storeDetail(Detail.DOMAIN, domain);
    }
    return {
        sdkVersion: SDK_VERSION,
        browser: window.navigator.userAgent,
        domain,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        screen: `${window.screen.width}x${window.screen.height}`,
    };
};
let initialised = false;
const isInitialised = () => initialised;
const initialise = async () => {
    initialised = true;
    try {
        const runtimeDetails = flattenProperties(getRuntimeDetails());
        const existingRuntimeId = getDetail$1(Detail.RUNTIME_ID);
        const body = {
            version: 1,
            data: {
                runtimeDetails,
                runtimeId: existingRuntimeId,
            },
        };
        const response = await post('/v1/sdk/initialise', body);
        // Get runtimeId and store it
        const { runtimeId } = response;
        storeDetail(Detail.RUNTIME_ID, runtimeId);
    }
    catch (error) {
        initialised = false;
    }
};

function errorBoundary(fn, fallbackResult) {
    return (...args) => {
        try {
            // Execute the original function
            const result = fn(...args);
            if (result instanceof Promise) {
                // Silent fail for now, in future
                // we can send errors to a logging service
                return result.catch(() => fallbackResult);
            }
            return result;
        }
        catch (error) {
            // As above, fail silently for now
            return fallbackResult;
        }
    };
}

function isTestEnvironmentFn() {
    if (isBrowser()) {
        return false;
    }
    if (typeof process === 'undefined') {
        return false;
    }
    // Consider using `ci-info` package for better results, though might fail as not browser safe.
    // Just use process.env.CI for now.
    return process.env.JEST_WORKER_ID !== undefined;
}
const isTestEnvironment = errorBoundary(isTestEnvironmentFn, false);

const GLOBALISE_KEY = 'imtbl__metrics';
const MEMORISE_TIMEFRAME = 5000;
const MEMORISE_MAX = 1000;
const getGlobalisedValue = (key, value) => getGlobalisedValue$1(GLOBALISE_KEY, key, value);
const getGlobalisedCachedFunction = (key, fn) => {
    // Some applications (esp backend, or frontends using the split bundles) can sometimes
    // initialise the same request multiple times. This will prevent multiple of the
    // same event,value from being reported in a 1 second period.
    const memorisedFn = memorise(fn, {
        lruOptions: { ttl: MEMORISE_TIMEFRAME, max: MEMORISE_MAX },
    });
    return getGlobalisedValue$1(GLOBALISE_KEY, key, memorisedFn);
};

const POLLING_FREQUENCY = 5000;
const trackFn = (moduleName, eventName, properties) => {
    const event = {
        event: `${moduleName}.${eventName}`,
        time: new Date().toISOString(),
        ...(properties && { properties: flattenProperties(properties) }),
    };
    addEvent(event);
};
/**
 * Track an event completion.
 * @param moduleName Name of the module being tracked (for namespacing purposes), e.g. `passport`
 * @param eventName Name of the event, use camelCase e.g. `clickItem`
 * @param properties Other properties to be sent with the event
 *
 * e.g.
 *
 * ```ts
 * track("passport", "performTransaction");
 * track("passport", "performTransaction", { transationType: "transfer" });
 * ```
 */
const track = errorBoundary(getGlobalisedCachedFunction('track', trackFn));
// Sending events to the server
const flushFn = async () => {
    // Don't flush if not initialised
    if (isInitialised() === false) {
        await initialise();
        return;
    }
    const events = getEvents();
    if (events.length === 0) {
        return;
    }
    // Track events length here, incase
    const numEvents = events.length;
    // Get details and send it with the track request
    const details = getAllDetails();
    const metricsPayload = {
        version: 1,
        data: {
            events,
            details,
        },
    };
    const response = await post('/v1/sdk/metrics', metricsPayload);
    if (response instanceof Error) {
        return;
    }
    // Clear events if successfully posted
    removeSentEvents(numEvents);
};
const flush = errorBoundary(flushFn);
// Flush events every 5 seconds
const flushPoll = async () => {
    await flush();
    setTimeout(flushPoll, POLLING_FREQUENCY);
};
let flushingStarted = false;
const startFlushing = () => {
    if (flushingStarted) {
        return;
    }
    flushingStarted = true;
    flushPoll();
};
// This will get initialised when module is imported.
if (!isTestEnvironment()) {
    errorBoundary(getGlobalisedValue('startFlushing', startFlushing))();
}

/**
 * Track an event and it's performance. Works similarly to `track`, but also includes a duration.
 * @param moduleName Name of the module being tracked (for namespacing purposes), e.g. `passport`
 * @param eventName Name of the event, e.g. `clickItem`
 * @param duration Duration of the event in milliseconds, e.g. `1000`
 * @param properties Other properties to be sent with the event, other than duration
 *
 * @example
 * ```ts
 * trackDuration("passport", "performTransaction", 1000);
 * trackDuration("passport", "performTransaction", 1000, { transationType: "transfer" });
 * ```
 */
const trackDuration = (moduleName, eventName, duration, properties) => track(moduleName, eventName, {
    ...(properties || {}),
    duration,
});
// Time Tracking Functions
// -----------------------------------
// Write a function to take multiple objects as arguments, and merge them into one object
const mergeProperties = (...args) => {
    const hasProperties = args.some((arg) => !!arg);
    if (!hasProperties) {
        return undefined;
    }
    let finalProperties = {};
    args.forEach((arg) => {
        if (arg) {
            finalProperties = {
                ...finalProperties,
                ...arg,
            };
        }
    });
    return finalProperties;
};
const getEventName = (flowName, eventName) => `${flowName}_${eventName}`;
// Generate a random uuid
const generateFlowId = () => {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};
const trackFlowFn = (moduleName, flowName, properties) => {
    // Track the start of the flow
    const flowStartEventName = getEventName(flowName, 'start');
    const flowId = generateFlowId();
    const startTime = performance.now();
    const flowStartTime = Math.round(startTime + performance.timeOrigin);
    let flowProperties = mergeProperties(properties, {
        flowId,
        flowStartTime,
    });
    trackDuration(moduleName, flowStartEventName, 0, flowProperties);
    const addFlowProperties = (newProperties) => {
        flowProperties = mergeProperties(flowProperties, newProperties, {
            flowId,
            flowStartTime,
        });
    };
    const addEvent = (eventName, eventProperties) => {
        const event = getEventName(flowName, eventName);
        // Calculate time since start
        const duration = Math.round(performance.now() - startTime);
        // Always send the details of the startFlow props with all events in the flow
        const mergedProps = mergeProperties(flowProperties, eventProperties, {
            flowId,
            flowStartTime,
            duration,
        });
        trackDuration(moduleName, event, duration, mergedProps);
    };
    const end = (endProperties) => {
        // Track the end of the flow
        const flowEndEventName = getEventName(flowName, 'end');
        const duration = Math.round(performance.now() - startTime);
        const mergedProps = mergeProperties(flowProperties, endProperties, {
            flowId,
            flowStartTime,
        });
        trackDuration(moduleName, flowEndEventName, duration, mergedProps);
    };
    return {
        details: {
            moduleName,
            flowName,
            flowId,
            flowStartTime,
        },
        addEvent: errorBoundary(addEvent),
        addFlowProperties: errorBoundary(addFlowProperties),
        end: errorBoundary(end),
    };
};
/**
 * Track a flow of events, including the start and end of the flow.
 * Works similarly to `track`
 * @param moduleName Name of the module being tracked (for namespacing purposes), e.g. `passport`
 * @param flowName Name of the flow, e.g. `performTransaction`
 * @param properties Other properties to be sent with the event, other than duration
 *
 * @example
 * ```ts
 * const flow = trackFlow("passport", "performTransaction", { transationType: "transfer" });
 * // Do something...
 * flow.addEvent("clickItem");
 * // Do something...
 * flow.addFlowProperties({ item: "item1" });
 * flow.addEvent("guardianCheck", {"invisible": "true"});
 * // Do something...
 * flow.addEvent("guardianCheckComplete");
 * flow.end();
 * ```
 */
const trackFlow = errorBoundary(trackFlowFn);

const parseIdentity = (params) => {
    if (params.passportId) {
        const key = `passport:${params.passportId.toLowerCase()}`;
        return key;
    }
    if (params.ethAddress) {
        const key = `ethAddress:${params.ethAddress.toLowerCase()}`;
        return key;
    }
    throw new Error('invalid_identity');
};
const identifyFn = (params) => {
    const identity = parseIdentity(params);
    if (!identity) {
        return;
    }
    storeDetail(Detail.IDENTITY, identity);
    track('metrics', 'identify', params.traits);
};
const identify = errorBoundary(identifyFn);

const setEnvironmentFn = (env) => {
    storeDetail(Detail.ENVIRONMENT, env);
};
const setEnvironment = errorBoundary(getGlobalisedValue('setEnvironment', setEnvironmentFn));
const setPassportClientIdFn = (passportClientId) => {
    storeDetail(Detail.PASSPORT_CLIENT_ID, passportClientId);
};
const setPassportClientId = errorBoundary(getGlobalisedValue('setPassportClientId', setPassportClientIdFn));
const setPublishableApiKeyFn = (publishableApiKey) => {
    storeDetail(Detail.PUBLISHABLE_API_KEY, publishableApiKey);
};
const setPublishableApiKey = errorBoundary(getGlobalisedValue('setPublishableApiKey', setPublishableApiKeyFn));
const getDetail = errorBoundary(getGlobalisedValue('getDetail', getDetail$1));

export { Detail, getDetail, identify, setEnvironment, setPassportClientId, setPublishableApiKey, track, trackDuration, trackFlow };
